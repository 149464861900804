import "./Header.scss";
import logo from "../../assets/logos/jl14-logo-dark.png";
import { Link } from 'react-router-dom';

function Header() {
    return (
        <>
            {/* Mobile Header */}
            <header className="mobile-header">
            <div className="mobile-header__content">
                <nav className="mobile-header__navigation" role="navigation">
                    {/* <div className="mobile-header__menu-toggle" id="menuToggle">
                        <input type="checkbox" />
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul className="mobile-header__list" id="menu">
                            <li className="mobile-header__list-item">
                                <NavLink to="/" className="mobile-header__list-item-link">Home</NavLink>
                            </li>
                            <li className="mobile-header__list-item">
                                <NavLink to="/about" className="mobile-header__list-item-link">About</NavLink>
                            </li>
                            <li className="mobile-header__list-item">
                                <NavLink to="/partnerships" className="mobile-header__list-item-link">Partnerships</NavLink>
                            </li>
                            <li className="mobile-header__list-item">
                                <NavLink to="/gallery" className="mobile-header__list-item-link">Gallery</NavLink>
                            </li>
                            <li className="mobile-header__list-item">
                                <NavLink to="/contact" className="mobile-header__list-item-link">Contact</NavLink>
                            </li>
                        </ul>
                    </div> */}
                    <div className="mobile-title__container">
                        <Link to="/" className="mobile-header__title-link"><img src={logo} className="mobile-header__logo" alt="JL14 Logo"/></Link>
                    </div>
                </nav>
            </div>
            </header>
            {/* Tablet and Desktop Header */}
            <header className="header">
                <Link to="/" className="header__logo-link">
                    <div className="header__logo-container">
                        <img src={logo} className="header__logo" alt="JL14 Logo"/>
                        <div className="header__name">
                            <span className="header__first-name">Janet</span>
                            <span className="header__last-name">Leung</span>
                        </div>
                    </div>
                </Link>
                {/* <nav className="header__nav">
                    <ul className="header__list">
                    <li className="header__list-item">
                            <NavLink 
                                to="/" 
                                className={({ isActive }) =>
                                    "header__list-item-link" + (isActive ? " header__list-item-link--active" : "")}>
                                Home
                            </NavLink>
                        </li>
                        <li className="header__list-item">
                            <NavLink 
                                to="/about" 
                                className={({ isActive }) =>
                                    "header__list-item-link" + (isActive ? " header__list-item-link--active" : "")}>
                                About
                            </NavLink>
                        </li>
                        <li className="header__list-item">
                            <NavLink 
                                to="/partnerships" 
                                className={({ isActive }) =>
                                    "header__list-item-link" + (isActive ? " header__list-item-link--active" : "")}>
                                Partnerships
                            </NavLink>
                        </li>
                        <li className="header__list-item">
                            <NavLink 
                                to="/gallery" 
                                className={({ isActive }) =>
                                    "header__list-item-link" + (isActive ? " header__list-item-link--active" : "")}>
                                Gallery
                            </NavLink>
                        </li>
                        <li className="header__list-item">
                            <NavLink 
                                to="/contact" 
                                className={({ isActive }) =>
                                    "header__list-item-link" + (isActive ? " header__list-item-link--active" : "")}>
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav> */}
            </header>
        </>
    );
}

export default Header;