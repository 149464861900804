import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Main from "./pages/Main/Main";
import Header from './components/Header/Header';
// import About from './pages/About/About';
// import Partnerships from './pages/Partnerships/Partnerships';
// import Gallery from './pages/Gallery/Gallery';
// import Contact from './pages/Contact/Contact';

function App() {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Main/>}/>
      {/* <Route path="/about" element={<About />} />
      <Route path="/partnerships" element={<Partnerships />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact" element={<Contact />} /> */}
    </Routes>
    </BrowserRouter>

  );
}

export default App;
