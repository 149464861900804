import "./Hero.scss";
import janetActionImage from "../../assets/images/hero-banner.jpg";

function Hero() {
    return (
        <div className="hero"> 

       

        <section className="main__bottom-image">
            <div className="main__bottom-image-container">
                <img className="main__image" src={janetActionImage} alt="Janet Catching Flyball" />
            </div>
        </section>
         </div>
    );
}

export default Hero;