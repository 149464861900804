import "./Socials.scss";
import instagramIcon from "../../assets/icons/Instagram.png";
import twitterIcon from "../../assets/icons/Twitter.png";
import linkedinIcon from "../../assets/icons/Linkedin.png";



function Socials() {
    return (
        <div className="socials">
            <ul className="socials__list">
                <li className="socials__list-item">
                    <a className="socials__list-item-link" href={"https://instagram.com/janetwleung"} target="_blank" rel="noreferrer">
                        <img className="socials__list-item-icon" src={instagramIcon} alt=""/>
                    </a>
                </li>
                <li className="socials__list-item">
                    <a className="socials__list-item-link" href={"https://twitter.com/janetleurng"} target="_blank" rel="noreferrer">
                        <img className="socials__list-item-icon" src={twitterIcon} alt=""/>
                    </a>
                </li>
                <li className="socials__list-item">
                    <a className="socials__list-item-link" href={"https://linkedin.com/in/janetwleung"} target="_blank" rel="noreferrer">
                        <img className="socials__list-item-icon" src={linkedinIcon} alt=""/>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Socials;