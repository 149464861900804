// import ComingSoon from "../../components/ComingSoon/ComingSoon";
import Hero from "../../components/Hero/Hero";
import Socials from "../../components/Socials/Socials";
// import janetImage from "../../assets/images/janet-ball.jpg";
// import janetActionImage from "../../assets/images/janet-double-play.jpg";
import "./Main.scss";
// import { Link } from "react-router-dom";
// import Footer from "../../components/Footer/Footer";

function Main() {
    return (
        <main className="main">
            <Hero />
            <Socials />
            {/* <section className="main__about">
                <div className="main__image-container">
                    <img className="main__image" src={janetImage} alt="Janet" />
                </div>
                <div className="main__text">
                    <h1 className="main__title">About Me</h1>
                    <h3 className="main__subtitle">Olympian, Canadian Women's National Team</h3>
                    <span>___</span>
                    <p className="main__paragraph">My name is Janet Leung and I'm an Olympic Bronze Medalist (Tokyo 2020). I play shortstop for
                        the Canadian Women's National Softball Team and have been on the team since 2017.
                        <br></br>
                        <br></br>
                        I am currently training out of the GTA and am working
                        towards competing in Udine, Italy next summer for the 2024 WBSC World Cup Finals.
                        <br></br>
                        <br></br>
                        I am passionate about inspiring and encouraging the
                        younger generation to stay active and involved with
                        sports. Playing softball has taught me countless life...</p>
                    <div className="main__link-container">
                        <Link to="/about" className="main__link">Read More</Link>
                    </div>
                </div>
            </section>
            <section className="main__bottom-image">
            <div className="main__bottom-image-container">
                    <img className="main__image" src={janetActionImage} alt="Turned Double Play vs. USA" />
                </div>
            </section>
            <Footer /> */}
        </main>
    );
}

export default Main;